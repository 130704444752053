import { useEffect, useState } from "react";
import PlanApi from "./services";
import { Button, Form, Modal } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  isValidName,
  isValidNumber,
  isValidPhoneNo,
} from "../../../../helper/regex";
import { t } from "i18next";

const AdminPlans = () => {
  const [getPricePlane, setGetPricePlane] = useState([]);
  const [changesMade, setChangesMade] = useState(true);
  const [planType, setPlanType] = useState("Monthly");
  const [planEnterpriseType, setPlanEnterpriseType] = useState("Yearly");
  const [getPriceEnterprisePlane, setGetPriceEnterprisePlane] = useState([]);

  const [load, setLoad] = useState(false);
  function getPlane() {
    PlanApi.pricePlann(planType)
      .then((response) => {
        if (response.status === 200) {
          console.log("pricePlann", response.data.data);
          const data = response.data.data;
          if (data.length > 0) {
            console.log(response.data.data);
            setGetPricePlane(response.data.data);
            setLoad(true);
          } else {
            setMessage("No Plan Found");
            setSeverity("error");
            setOpen(true);
          }
        } else if (response.status === 400) {
          console.error("Bad request:", response.data);
        } else if (response.status === 404) {
          console.error("Not found:", response.data);
        } else if (response.status === 500) {
          console.error("Internal server error:", response.data);
        } else if (response.status === 204) {
          console.error("No content:", response.data);
          setMessage("No Plan Found");
          setSeverity("error");
          setOpen(true);
        } else {
          console.error("Unexpected error:", response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getEnterprisePlane() {
    PlanApi.pricePlann(planEnterpriseType)
      .then((response) => {
        if (response.status === 200) {
          console.log("pricePlann", response.data.data);
          const data = response.data.data;
          if (data.length > 0) {
            console.log(response.data.data);
            setGetPriceEnterprisePlane(response.data.data);
            setLoad(true);
          } else {
            setMessage("No Plan Found");
            setSeverity("error");
            setOpen(true);
          }
        } else if (response.status === 400) {
          console.error("Bad request:", response.data);
        } else if (response.status === 404) {
          console.error("Not found:", response.data);
        } else if (response.status === 500) {
          console.error("Internal server error:", response.data);
        } else if (response.status === 204) {
          console.error("No content:", response.data);
          setMessage("No Plan Found");
          setSeverity("error");
          setOpen(true);
        } else {
          console.error("Unexpected error:", response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getPlane();
    getEnterprisePlane();
  }, []);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleClosemessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const dataJson = {
    planName: "",
    price: "",
    priceOfPlan: "",
    shareCount: "",
    validityDays: "",
    features: [],
  };

  const [show, setShow] = useState(false);
  const [planData, setPlanData] = useState(dataJson);
  const [selectedEditFeatures, setSelectedEditFeatures] = useState([]);

  function handleChange(e) {
    debugger;
    const { name, value } = e.target;
    // Function to validate input based on field name
    const isValidInput = (name, value) => {
      switch (name) {
        case "planName":
          return isValidName(value);
        case "price":
        case "priceOfPlan":
        case "shareCount":
        case "validityDays":
          return isValidNumber(value);
        default:
          return true; // If no specific validation, consider it valid
      }
    };

    // Validate the input and return early if invalid
    if (!isValidInput(name, value)) {
      return;
    }
    setPlanData({
      ...planData,
      [name]: value,
    });
    setChangesMade(false);
  }

  const handleShow = (data) => {
    setPlanData(data);
    const selectedFeatureIds = data?.features?.map(
      (feature) => feature.featureId
    );
    setSelectedEditFeatures(selectedFeatureIds);
    setShow(true);
    setTimeout(() => {
      console.log(planData);
    }, 500);
  };

  function updateData() {
    console.log(planData);
    // Prepare features data
    const updatedFeatures = selectedEditFeatures.map((featureId) => ({
      featureId,
      features: features.find((f) => f.featureId === featureId)?.features || "",
    }));

    PlanApi.updatepricePlann(planData.planId, {
      ...planData,
      features: updatedFeatures,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setOpen(true);
          setMessage(response.data.message);
          setSeverity("success");
          handleClose();
          getPlane();
          setChangesMade(true);
          getEnterprisePlane();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const form = event.currentTarget;

    // Checking form validity
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Checking if all required fields are filled
      if (
        planData?.planName?.trim() !== "" &&
        planData.price !== "" &&
        planData.priceOfPlan !== "" &&
        planData.shareCount !== "" &&
        planData.validityDays !== "" &&
        selectedEditFeatures.length >= 3 // Check if at least 3 features are selected
      ) {
        updateData();
      } else {
        setOpen(true);
        setMessage("Fill all required fields");
        setSeverity("error");
      }
    }

    setValidated(true);
  };

  // const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [features, setFeatures] = useState([]);

  function handleEditCheckboxChange(featureId) {
    setSelectedEditFeatures((prevSelectedFeatures) =>
      prevSelectedFeatures.includes(featureId)
        ? prevSelectedFeatures.filter((id) => id !== featureId)
        : [...prevSelectedFeatures, featureId]
    );
    setChangesMade(false);
  }

  const getAllPlansFeatures = () => {
    PlanApi.getAllPlansFeatures()
      .then((response) => {
        setFeatures(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllPlansFeatures();
  }, []);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-6 col-lg-6">
              <h5 className="mb-0">{t("Plans")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 align-items-center">
              <div style={{ marginBottom: "7px", fontWeight: 500 }}>
                {t("Monthly Plan")}
              </div>
              {getPricePlane.map((item) => (
                <div className="border p-4 mb-4 border-20">
                  <div className="d-flex mb-3 xs-block">
                    <label className="form-label text-dark font-18 xs-block p_0">
                      {item.planName}
                    </label>{" "}
                    <span className="text-gray font-14 ps-4 xs-block p_0">
                      ({item.shareCount} {t("Users")})
                    </span>
                    <div className="ms-auto xs-block p_0">
                      <span className="mb-0 font-18">${item.price}</span>
                      <span className="text-gray font-14">/{t("month")}</span>
                    </div>
                  </div>
                  <div className="d-flex mb-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary py-1 font-14"
                      onClick={() => handleShow(item)}
                    >
                      {t("Edit_Plan")}
                    </button>
                    {/* <button type="button" className="btn btn-danger py-1 font-14 ms-4">Delete Plan</button> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-md-6 col-lg-6 align-items-center">
              <div style={{ marginBottom: "7px", fontWeight: 500 }}>
                {t("Yearly Plan")}
              </div>
              {getPriceEnterprisePlane.map((item) => (
                <div className="border p-4 mb-4 border-20">
                  <div className="d-flex mb-3 xs-block">
                    <label className="form-label text-dark font-18 xs-block p_0">
                      {item.planName}
                    </label>{" "}
                    <span className="text-gray font-14 ps-4 xs-block p_0">
                      ({item.shareCount} {t("Users")})
                    </span>
                    <div className="ms-auto xs-block p_0">
                      <span className="mb-0 font-18">${item.price}</span>
                      <span className="text-gray font-14">/{t("month")}</span>
                    </div>
                  </div>
                  <div className="d-flex mb-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary py-1 font-14"
                      onClick={() => handleShow(item)}
                    >
                      {t("Edit_Plan")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit_Plan")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>{t("Plan Name")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="planName"
                maxLength={25}
                value={planData.planName}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-3">
              <Form.Label>{t("Price")} ($)</Form.Label>
              <Form.Control
                required
                type="text"
                name="price"
                maxLength={5}
                value={planData.price}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-3">
              <Form.Label>{t("Price of Plan")}</Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={5}
                name="priceOfPlan"
                value={planData.priceOfPlan}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-3">
              <Form.Label>{t("Share Count")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="shareCount"
                maxLength={6}
                value={planData.shareCount}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter share count
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-3">
              <Form.Label>{t("Validity Days")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="validityDays"
                maxLength={3}
                value={planData.validityDays}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter validity date
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationCustom06" className="mb-3">
              <Form.Label>{t("List of Features")}</Form.Label>
              {features?.map((item) => (
                <Form.Check
                  type="checkbox"
                  key={item.featureId}
                  id={`checkbox-${item.featureId}`}
                  label={item.features}
                  checked={selectedEditFeatures.includes(item.featureId)}
                  onChange={() => handleEditCheckboxChange(item.featureId)}
                />
              ))}
              {validated && selectedEditFeatures.length < 3 && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  Please select at least 3 features
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            disabled={changesMade}
            variant="primary"
            onClick={handleSubmit}
          >
            {t("Update")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClosemessage}
        >
          <Alert
            onClose={handleClosemessage}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AdminPlans;
