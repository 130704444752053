import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getNotification = () => {
  debugger;
  return http.get(`/admin/notification`, {
    headers: {
      ...authHeader(),
    },
  });
};

const findNotificationById = (id) => {
  return http.get(`/admin/get/${id}`);
};

const NotificaionServices = {
  getNotification,
  findNotificationById,
};

export default NotificaionServices;
