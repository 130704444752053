import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getTutotials = (page, pageSize, search, sortBy, sortDir) => {
  return http.get(
    `/tutorials/admin/pagination?title=${search}&pageNumber=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const getTutotial = (id) => {
  return http.get(`/tutorials/get/${id}`);
};
const addTutotial = (data) => {
  debugger;
  return http.post(`/tutorials/save`, data, {
    headers: { ...authHeader() },
  });
};

const updateTutotial = (id, data) => {
  debugger;
  return http.put(`/tutorials/edit/${id}`, data);
};

const imgeUpload = (data) => {
  debugger;
  return http.post(`/upload/tutorials/image`, data);
};

const isPublish = (id, status) => {
  return http.patch(`/tutorials/${id}/publish?isPublish=${status}`);
};

const deleteTutotial = (id) => {
  return http.delete(`/tutorials/delete/${id}`);
};

const imageUploadCKEditor = (formData) => {
  debugger;
  debugger;
  return http.post(`/upload/blog/ckeditor/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const TutorialsService = {
  getTutotials,
  getTutotial,
  updateTutotial,
  addTutotial,
  imgeUpload,
  isPublish,
  deleteTutotial,
  imageUploadCKEditor,
};

export default TutorialsService;
