export function formatDate(date) {
  // Define month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get parts of the date
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  // Return formatted date string
  return `${month} ${day}, ${year} at ${hours}:${formattedMinutes} ${period}`;
}

export const formatDateWithTime = (dateString) => {
  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    date.toLocaleDateString("en-US", options).replace(",", "") +
    " at " +
    date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
  );
};

export const formatDateOnly = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export default formatDate;
