import http from "../../../../baseURL/httpcommon";

const getContacts = (page, pageSize, search, sortBy, sortDir) => {
  return http.get(
    `/contact/all?pageNumber=${page}&pageSize=${pageSize}&searchQuery=${search}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const deleteContactById = (id) => {
  return http.delete(`/contact/${id}`);
};

const ContactService = {
  getContacts,
  deleteContactById,
};

export default ContactService;
