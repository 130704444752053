import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CompaniesApi from "./services";
import ServerSideTable from "../../../common components/serverSideTable";
import {
  Button,
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ViewDetails from "./viewDetails";
const ViewRequests = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState("createdAt"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const [companyName, setCompanyName] = useState("");
  const [requestType, setRequestType] = useState("");

  const onChangeCompanyName = (e) => {
    const name = e.target.value;
    setCompanyName(name);
  };

  const onChangeRequestTypeStatus = (e) => {
    const requestType = e.target.value;
    setRequestType(requestType);
  };

  const handleSearch = () => {
    setPage(0);
    changeList();
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    setLoading(true);
    CompaniesApi.viewRequestsPagination(
      page,
      pageSize,
      companyName,
      requestType,
      sortBy,
      sortDir
    )
      .then((response) => {
        const { totalPages, totalElements, content } = response.data.data;
        setCount(totalPages);
        setTotalItems(totalElements);
        setData(content);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(changeList, [page, pageSize, sortBy, sortDir]);

  const refreshData = () => {
    debugger;
    changeList();
  };

  const columns = [
    { field: "companyId", headerName: t("Request Id"), width: 100 },
    { field: "companyName", headerName: t("Company_name"), width: 200 },
    { field: "email", headerName: t("Email"), width: 200 },
    { field: "phoneNo", headerName: t("Phone No"), width: 200 },
    { field: "ssn", headerName: t("SSN"), width: 200 },
    {
      field: "ecompanyRequestType",
      headerName: t("Status"),
      align: "center",
      sortable: false,
      width: 100,
      renderCell: (param) => {
        const status = param.row.ecompanyRequestType;

        return status ? (
          <Chip
            label={status}
            color={
              status === "Approved"
                ? "success"
                : status === "Pending"
                ? "warning"
                : status === "Rejected"
                ? "error"
                : "default"
            }
          />
        ) : (
          <Chip label="N/A" color="default" />
        );
      },
    },
    {
      field: "view",
      headerName: t("Action"),
      sortable: false,
      renderCell: (param) => {
        return <ViewDetails data={param.row} refreshData={refreshData} />;
      },
      width: 120,
    },
  ];

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("View Requests")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Link
                    to="/dashboard/admin/companies"
                    className="btn btn-outline-secondary btn-blue font-14 ms-2"
                  >
                    {t("Back")}
                  </Link>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    id="companyName"
                    size="small"
                    name="companyName"
                    label={t("Search by Company Name")}
                    variant="outlined"
                    value={companyName}
                    onChange={onChangeCompanyName}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <FormControl fullWidth size="small">
                    <Select
                      id="requestType"
                      displayEmpty
                      inputProps={{ "aria-label": "Status" }}
                      value={requestType}
                      onChange={onChangeRequestTypeStatus}
                      onKeyDown={handleKeyDown}
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"Approved"}>Approved</MenuItem>
                      <MenuItem value={"Rejected"}>Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    {t("SEARCH")}
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={pageSize}
                page={page}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="companyId"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRequests;
