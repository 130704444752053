import { createBrowserRouter } from "react-router-dom";
import AdminLogin from "./authentication/admin/login";
import ForgetPassword from "./authentication/forgetpassword";
import Dashboard from "./dashboard";
import AdminCompanies from "./dashboard/module/admin/companies";
import AdminHome from "./dashboard/module/admin/home";
import AdminPlans from "./dashboard/module/admin/plans";
import AdminNotification from "./dashboard/module/admin/notification/index";

import ViewRequests from "./dashboard/module/admin/companies/viewRequests";
import ApplicationSetting from "./dashboard/module/admin/settings/applicationSetting";
import ChangePassword from "./dashboard/module/admin/settings/changePassword";
import SettingCompany from "./dashboard/module/admin/settings/companySetting";
import DevloperSetting from "./dashboard/module/admin/settings/devloperSetting";
import LanguagePreference from "./dashboard/module/admin/settings/languagePreference";
import AdminSmtp from "./dashboard/module/admin/settings/smtp";
// import TermCondition from "./dashboard/module/company/settings/termConditions";
// import PrivacyPolicies from "./dashboard/module/company/settings/privacyPolicies";
import BillingPayment from "./dashboard/module/admin/billingPayment";

import PrivateRoute from "./PrivateRoute";
import WorkerSkills from "./dashboard/module/admin/settings/workerSkills";

// import TermConditions from "./dashboard/module/supervisor/settings/termConditions";
// import PrivacyPolicy from "./dashboard/module/supervisor/settings/privacyPolicies";
import NotFoundPage from "./pageNotFound";
import Blog from "./dashboard/module/admin/blog/Blog";
import AddBlog from "./dashboard/module/admin/blog/AddBlog";
import AddTutorials from "./dashboard/module/admin/tutorials/AddTutorials";
import Tutorials from "./dashboard/module/admin/tutorials/Tutorials";
import EnterPrisePlans from "./dashboard/module/admin/plans/enterprisePlans";
import Contact from "./dashboard/module/admin/contact";
import EditBlog from "./dashboard/module/admin/blog/EditBlog";
import EditTutorials from "./dashboard/module/admin/tutorials/EditTutorials";

//------ Policies -----------
// import TermCondition from "./policies/termcondition";

// import ContactUs from "./policies/contactus";
// const token = localStorage.getItem("token");
// console.log("token", token);

// const isValidToken = () => {
//   const token = localStorage.getItem("token");
//   console.log("Toek", token)
//   // Perform token validation here, e.g., check token expiration, etc.
//   return token !== null; // Example: Just checking if the token exists
// };

// const PrivateRoute = ({ element }) => {
//   console.log("isValidToken()", isValidToken())
//   return isValidToken() ? element : <Navigate to="/" replace={true} />;
// };

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    element: <AdminLogin />,
  },
  {
    path: "/login",
    element: <AdminLogin />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },

  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard/admin/home",
        element: <PrivateRoute element={<AdminHome />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/companies",
        element: <PrivateRoute element={<AdminCompanies />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/companies/viewrequests",
        element: <PrivateRoute element={<ViewRequests />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/plans/list",
        element: <PrivateRoute element={<AdminPlans />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/enterprise/list",
        element: (
          <PrivateRoute element={<EnterPrisePlans />} fallbackPath="/" />
        ),
      },
      {
        path: "/dashboard/admin/billingpayments",
        element: <PrivateRoute element={<BillingPayment />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/contacts",
        element: <PrivateRoute element={<Contact />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/changepassword",
        element: <PrivateRoute element={<ChangePassword />} fallbackPath="/" />,
      },

      //-------------company----------------

      {
        path: "/dashboard/admin/settings/SettingCompany",
        element: <PrivateRoute element={<SettingCompany />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/blog",
        element: <PrivateRoute element={<Blog />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/edit-blog/:id",
        element: <PrivateRoute element={<EditBlog />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/tutorials/add",
        element: <PrivateRoute element={<AddTutorials />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/tutorials",
        element: <PrivateRoute element={<Tutorials />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/edit-tutorials/:id",
        element: <PrivateRoute element={<EditTutorials />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/blog/add",
        element: <PrivateRoute element={<AddBlog />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/smtp",
        element: <PrivateRoute element={<AdminSmtp />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/settings/devloperSetting",
        element: (
          <PrivateRoute element={<DevloperSetting />} fallbackPath="/" />
        ),
      },
      {
        path: "/dashboard/admin/settings/devloperSetting",
        element: (
          <PrivateRoute element={<DevloperSetting />} fallbackPath="/" />
        ),
      },

      {
        path: "/dashboard/admin/settings/language",
        element: (
          <PrivateRoute element={<LanguagePreference />} fallbackPath="/" />
        ),
      },
      {
        path: "/dashboard/admin/settings/appSetting",
        element: (
          <PrivateRoute element={<ApplicationSetting />} fallbackPath="/" />
        ),
      },
      {
        path: "/dashboard/admin/settings/workerskill",
        element: <PrivateRoute element={<WorkerSkills />} fallbackPath="/" />,
      },
      {
        path: "/dashboard/admin/notification",
        element: (
          <PrivateRoute element={<AdminNotification />} fallbackPath="/" />
        ),
      },

      // ---------------------worker/supervisor----------------------------
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
    fallbackPath: "/",
  },
]);

export default router;
