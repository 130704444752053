import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const pricePlann = (planType) => {
  return http.get(`subscription/allSubscriptionPlan?planType=${planType}`);
};
const updatepricePlann = (id, data) => {
  return http.put(`subscription/subscription/update/${id}`, data);
};

const getAllPlansFeatures = () => {
  return http.get(`/features/all`);
};

const saveEnterprisePlan = (data) => {
  return http.post(`/subscription/save`, data);
};

const getEnterprisePlans = (
  page,
  pageSize,
  name,
  planType,
  sortBy,
  sortDir
) => {
  return http.get(
    `/subscription/enterprise/plan?pageNumber=${page}&pageSize=${pageSize}&name=${name}&planType=${planType}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const PlanApi = {
  pricePlann,
  updatepricePlann,
  getAllPlansFeatures,
  saveEnterprisePlan,
  getEnterprisePlans,
};

export default PlanApi;
