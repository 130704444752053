import http from "../../../../baseURL/httpcommon";

const getCompanies = (
  page,
  pageSize,
  companyName,
  paymentStatus,
  sortBy,
  sortDir
) => {
  return http.get(
    `admin/companies?pageNumber=${page}&pageSize=${pageSize}&companyName=${companyName}&currentStatus=${paymentStatus}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const viewRequests = () => {
  return http.get("company/new/request");
};

const viewRequestsPagination = (
  page,
  pageSize,
  companyName,
  requestType,
  sortBy,
  sortDir
) => {
  return http.get(
    `company/new/request?pageNumber=${page}&pageSize=${pageSize}&companyName=${companyName}&requestType=${requestType}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};
const changeStatus = (email, event, reason) => {
  const data = {
    email: email,
    status: event,
    reasonForRejection: reason,
  };
  return http.patch(`admin/company/change/status`, data);
};
const changeCompanyStatus = (email, event) => {
  const data = {
    email: email,
    status: event,
  };
  return http.patch(`company/change/status`, data);
};

const getEnterprisePlans = () => {
  return http.get("/subscription/enterprise/plan/active");
};
const CompaniesApi = {
  getCompanies,
  viewRequests,
  viewRequestsPagination,
  changeStatus,
  changeCompanyStatus,
  getEnterprisePlans,
};

export default CompaniesApi;
