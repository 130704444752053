import { useEffect, useState } from "react";
import SettingApi from "./services";
import { Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { isValidNumber } from "../../../../helper/regex";
import { tree } from "d3";

const AdminSmtp = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [email, setEmail] = useState("");
  const [changesMade, setChangesMade] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const dataJson = {
    id: 1,
    host: "",
    port: "",
    username: "",
    password: "",
    debug: "",
    enableSsl: "",
  };
  const [data, setData] = useState(dataJson);

  function setValue(e) {
    const { name, value, checked } = e.target;
    if (name == "enableSsl") {
      if (name === "port" && !isValidNumber(value)) {
        return;
      }

      setData({
        ...data,
        [name]: checked,
      });
    } else {
      if (name === "port" && !isValidNumber(value)) {
        return;
      }

      setData({
        ...data,
        [name]: value,
      });
    }
    setChangesMade(false);
    console.log(data);
  }
  function companyGet(data) {
    debugger;
    SettingApi.smtpGet(data).then((respons) => {
      const responsData = respons.data.data;
      console.log("smtp_get", responsData);
      setData({
        ...data,
        host: responsData.host,
        port: responsData.port,
        username: responsData.username,
        supportEmail: responsData.supportEmail,
        password: responsData.password,
        debug: responsData.debug,
        enableSsl: responsData.enableSsl,
      });
    });
  }
  function smtpUpdate() {
    if (
      data?.host?.trim() === "" ||
      String(data?.port || "").trim() === "" ||
      data?.username?.trim() === "" ||
      data?.password?.trim() === ""
    ) {
      // If any required field is empty, show error message
      setMessage("Fill all required fields.");
      setSeverity("error");
      setOpen(true);
      return;
    }
    SettingApi.smtpUpdate(data)
      .then((respons) => {
        console.log(respons);
        if (respons.data.status == true) {
          setOpen(true);
          setMessage(respons.data.message);
          setSeverity("success");
        }
        setChangesMade(true);
      })
      .catch((e) => {
        if (e.response.status == 404) {
          setOpen(true);
          setMessage(e.response.data.message);
          setSeverity("error");
        }
        setChangesMade(true);
      });
  }
  function testMail() {
    SettingApi.testMail(email)
      .then((respons) => {
        console.log(respons);
        if (respons.data.status == true) {
          setOpen(true);
          setMessage(respons.data.message);
          setSeverity("success");
        }
      })
      .catch((e) => {
        if (e.response.status == 404) {
          setOpen(true);
          setMessage(e.response.data.message);
          setSeverity("error");
        }
      });
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      smtpUpdate();
    }

    setValidated(true);
  };
  const [mailValidated, setMailValidated] = useState(false);

  const mailhandleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const regex = /^[A-Za-z][A-Za-z0-9_.@]{8,100}$/;
      if (email?.trim() !== "" && regex.test(email)) {
        testMail();
      } else {
        setOpen(true);
        setMessage("Please Enter Valid Email Address");
        setSeverity("error");
      }
    }
    setMailValidated(true);
  };
  useEffect(() => {
    companyGet(data);
  }, []);
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h5 className="mb-0">{t("SMTP Settings")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Email address")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Email address"
                    value={data.username}
                    name="username"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Password")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Password"
                    value={data.password}
                    name="password"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Host")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="host"
                    value={data.host}
                    name="host"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Port")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={3}
                    placeholder="port"
                    value={data.port}
                    name="port"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <div className="form-check pt-4 mt-2">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={t("Enable SSL")}
                    checked={data.enableSsl}
                    name="enableSsl"
                    onChange={setValue}
                  />
                </div>
              </div>
            </div>
          </Form>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-4">
              <button
                disabled={changesMade}
                class="btn btn-primary mt-4 px-4"
                onClick={handleSubmit}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h5 className="mb-0">{t("Send Test Notification")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-4">
              <Form noValidate validated={mailValidated}>
                <Form.Group controlId="validationCustotest">
                  <Form.Label>{t("Email address")}</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    placeholder="Email address"
                    value={email}
                    name="username"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </Form>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-4">
              <button
                type="button"
                class="btn btn-primary mt-4 px-4"
                onClick={mailhandleSubmit}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AdminSmtp;
