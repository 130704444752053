import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const changePassword = (data) => {
  return http.post(
    "user/changepassword",
    data
    // {
    //   headers: {
    //     ...authHeader(),
    //   },
    // }
  );
};

const smtpGet = () => {
  return http.get("smtp/get");
};
const smtpUpdate = (data) => {
  return http.put("smtp/update", data);
};
const testMail = (email) => {
  return http.post(`smtp/test/smtp/${email}`);
};
const applicationGet = (data) => {
  return http.get("application/get");
};
const applicationUpdate = (data) => {
  return http.put("application/update", data);
};
const companyGet = (data) => {
  return http.get("settings/company");
};
const companyUpdate = (data) => {
  return http.put("settings/company", data);
};
const devSetGet = (data) => {
  return http.get("developer/get");
};
const devSetUpdate = (data) => {
  return http.put("developer/update", data);
};
const workerskill = (page, pageSize, name, sortBy, sortDir) => {
  return http.get(
    `admin/skills?pageNumber=${page}&pageSize=${pageSize}&name=${name}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};
const workerskillSave = (data) => {
  return http.post("/admin/skills/save", data);
};

const changeSkillStatus = (id, status) => {
  return http.patch(`/admin/skills/change-status/${id}?status=${status}`);
};

const workerSkillUpdate = (data) => {
  debugger;
  return http.put(`/admin/skills/update/${data.id}`, data);
};

const SettingApi = {
  changePassword,
  smtpGet,
  smtpUpdate,
  testMail,
  applicationGet,
  applicationUpdate,
  companyGet,
  companyUpdate,
  devSetGet,
  devSetUpdate,
  workerskill,
  changeSkillStatus,
  workerskillSave,
  workerSkillUpdate,
};

export default SettingApi;
