import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getAdminDashboard = () => {
  return http.get(`/dashboard/admin`, {
    headers: {
      ...authHeader(),
    },
  });
};
const getStaticPlans = () => {
  return http.get(`/subscription/api/subscription/plan-counts`);
};

const getConstructionRequests = () => {
  return http.get(`company/new/request`);
};

const getConstructionRequestsTop3 = () => {
  return http.get(`/company/new/request/Top3`);
};

const AdminApi = {
  getAdminDashboard,
  getStaticPlans,
  getConstructionRequests,
  getConstructionRequestsTop3,
};

export default AdminApi;
