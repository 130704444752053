import { useEffect } from "react";
import { useState } from "react";
import Files from "react-files";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import ViewDetails from "./viewDetails";
import { t } from "i18next";
import { formatDateOnly } from "../../../../helper/DateFormater";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TutorialsService from "./services";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Tutorials = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [errors, setErrors] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [sortBy, setSortBy] = useState("createdOn"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir
  const [search, setSearch] = useState("");

  const onSearchChange = (e) => {
    const search = e.target.value;
    setSearch(search);
  };

  const initialTutorialData = {
    id: "",
    title: "",
    subTitle: "",
    content: "",
    link: "",
    isPublish: "",
  };

  const [currentData, setCurrentData] = useState(initialTutorialData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentData({ ...currentData, [name]: value });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentData.title) newErrors.title = "Title is required";
    if (!currentData.subTitle) newErrors.subTitle = "Sub Title is required";
    if (!currentData.content) newErrors.content = "Content is required";
    if (!currentData.link) newErrors.imageUrl = "Video Link is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    setLoading(true);
    TutorialsService.getTutotials(page, pageSize, search, sortBy, sortDir).then(
      (respons) => {
        const { totalPages, totalElements, currentPage, content } =
          respons.data.data;
        // const updatedContent = content.map((company) => {
        //   return { ...company, id: company.companyId };
        // });
        setCount(totalPages);
        setTotalItems(totalElements);
        setData(content);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    changeList();
  }, [page, pageSize, sortBy, sortDir]);

  const columns = [
    { field: "id", headerName: t("Tutorial Id"), width: 100 },
    { field: "title", headerName: t("Title"), flex: 1 },

    {
      field: "createdOn",
      headerName: t("Created At"),
      flex: 1,
      renderCell: (param) => {
        return formatDateOnly(param.row.createdOn);
      },
    },
    {
      field: "publish",
      headerName: t("Is Active"),
      width: 120,
      sortable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => {
        const toggleStatus = async () => {
          setLoading(true);
          const newStatus = !params.value; // Toggle the status
          try {
            // Update status via API call
            const response = await TutorialsService.isPublish(
              params.row.id,
              newStatus
            );

            const updatedData = data?.map((row) => {
              // Log the row id and params.row.id to the console
              console.log("Mapping Row ID:", row.id);
              console.log("Params Row ID:", params.row.id);

              // Check if the condition row.id === params.row.id is true
              if (row.id === params.row.id) {
                console.log("Condition is true for Row ID:", row.id);
              } else {
                console.log("Condition is false for Row ID:", row.id);
              }

              return row.id === params.row.id
                ? { ...row, publish: newStatus }
                : row;
            });

            setData(updatedData);
            setOpen(true);
            setSeverity("success");
            setMessage(response.data.message);
            // Optionally update params.value directly (may not be necessary)
            params.value = newStatus;
            getCompanies();
          } catch (error) {
            console.error("Error updating status:", error);
            setOpen(true);
            setSeverity("error");
            setMessage("Something when wrong!");
          }
          setLoading(false);
        };

        return (
          <FormControlLabel
            control={
              <IOSSwitch
                checked={params.value}
                onChange={toggleStatus}
                sx={{ m: 1 }}
              />
            }
          />
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        const onClickEdit = async () => {
          // TutorialsService.getTutotial(params.row.id)
          //   .then((response) => {
          //     const data = response.data.data;
          //     setCurrentData(data);
          //   })
          //   .catch((error) => {
          //     console.log("error", error);
          //   });
          navigate(`/dashboard/admin/settings/edit-tutorials/${params.row.id}`);
        };

        const onClickDelete = async () => {
          setSelectedTutorial(params.row);
          handleShowConfirmationModal();
          // try {
          //   await TutorialsService.deleteTutotial(params.row.id);

          //   const updatedData = data.filter((row) => row.id !== params.row.id);

          //   // Update the state with the new list
          //   setData(updatedData);
          //   setOpen(true);
          //   setSeverity("success");
          //   setMessage("Tutorial deleted successfully");
          // } catch (error) {
          //   console.log("Error deleting tutorial:", error);
          //   setOpen(true);
          //   setSeverity("error");
          //   setMessage("Something went wrong!");
          // }
        };

        return (
          <>
            <Button
              onClick={() => {
                onClickEdit();
                handleShowEditProduct();
              }}
              variant="contained"
              size="small"
              color="primary"
              style={{ marginLeft: 16 }}
              startIcon={<EditIcon />}
            >
              {t("Edit")}
            </Button>
            <Button
              onClick={() => {
                onClickDelete();
              }}
              variant="contained"
              size="small"
              color="error"
              style={{ marginLeft: 16 }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </>
        );
      },
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [showEditProduct, setShowEditProduct] = useState(false);
  const handleCloseEditProduct = () => setShowEditProduct(false);
  const handleShowEditProduct = () => setShowEditProduct(true);

  const updateTutorial = () => {
    if (!validateFields()) return;
    debugger;
    TutorialsService.updateTutotial(currentData.id, currentData)
      .then((response) => {
        setCurrentData({ ...currentData });
        console.log(response.data);
        setOpen(true);
        setSeverity("success");
        setMessage(response.data.message);
        handleCloseEditProduct();
        changeList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteTutorial = () => {
    TutorialsService.deleteTutotial(selectedTutorial.id)
      .then((response) => {
        changeList();
        setOpen(true);
        setSeverity("success");
        setMessage("Deleted successfully!");
        handleCloseConfirmationModal();
      })
      .catch((e) => {
        console.log(e);
        setOpen(true);
        setSeverity("error");
        setMessage("Error occurred while deleting the tutorial.");
      });
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = () => {
    setPage(0);
    changeList();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Tutorials List")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/dashboard/admin/settings/tutorials/add"
                  >
                    {t("Add New Tutorial")}
                  </Button>
                </div>
              </div>

              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    id="search"
                    size="small"
                    name="search"
                    label={`${t("Search by Title")}`}
                    variant="outlined"
                    value={search}
                    onChange={onSearchChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    {t("SEARCH")}
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={pageSize}
                page={page}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="id"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showEditProduct}
        onHide={handleCloseEditProduct}
        size="lg"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderBottom:
              "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ marginTop: 2 }}
            >
              <div>
                <TextField
                  fullWidth
                  id="id"
                  label="Id"
                  name="id"
                  value={currentData.id}
                  onChange={handleInputChange}
                  hidden
                />
              </div>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={currentData.title}
                  onChange={handleInputChange}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="subTitle"
                  label="Sub Title"
                  name="subTitle"
                  value={currentData.subTitle}
                  onChange={handleInputChange}
                  error={!!errors.subTitle}
                  helperText={errors.subTitle}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="link"
                  label="Video Link"
                  name="link"
                  value={currentData.link}
                  onChange={handleInputChange}
                  error={!!errors.link}
                  helperText={errors.link}
                />
              </Grid>
            </Grid>
          </Box>
          <div>
            <p>Content :</p>
            <CKEditor
              editor={ClassicEditor}
              data={currentData.content}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCurrentData((prevData) => ({
                  ...prevData,
                  content: data,
                }));
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  content: null,
                }));
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            {errors.content && <p style={{ color: "red" }}>{errors.content}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleCloseEditProduct}
          >
            Close
          </Button>
          <Button
            disabled={changesMade}
            variant="contained"
            onClick={updateTutorial}
            sx={{ ml: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmationModal}
        onHide={handleCloseConfirmationModal}
        style={{ marginTop: "100px" }}
      >
        <Modal.Body className="modal-content text-center">
          <div className="modal-body font-18 p-5 text-dark">
            {t("Are you sure you want to delete this tutorial?")}
          </div>

          <div className="pb-5 border-0">
            <button
              type="button"
              className="btn btn-primary px-4 py-3 w-25"
              style={{ marginRight: "1.5rem" }}
              onClick={deleteTutorial}
            >
              {t("Delete")}
            </button>

            <button
              type="button"
              className="btn btn-danger px-4 py-3 w-25 "
              data-bs-dismiss="modal"
              onClick={handleCloseConfirmationModal}
            >
              {t("Cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showConfirmationModal}
        onHide={handleCloseConfirmationModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this tutorial?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="error" onClick={deleteTutorial}>
            Delete
          </Button>
          <Button variant="contained" onClick={handleCloseConfirmationModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Tutorials;
