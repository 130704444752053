import { useEffect } from "react";
import { useState } from "react";
import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import ViewDetails from "./viewDetails";
import { t } from "i18next";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TutorialsService from "./services";

function uploadAdapter(loader) {
  debugger;
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // Load the file
          const formData = new FormData();
          formData.append("multipartFile", file); // Append the file to formData
          const response = await TutorialsService.imageUploadCKEditor(formData);
          // Resolve with the URL of the uploaded image
          resolve({
            default: `${response.data.message}`,
          });
        } catch (error) {
          reject(error.message || "Upload failed"); // Reject with a message
        }
      });
    },
    abort: () => {
      // Handle abort functionality if necessary
    },
  };
}

function uploadPlugin(editor) {
  debugger;
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const EditTutorials = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [errors, setErrors] = useState({});
  const [changesMade, setChangesMade] = useState(false);

  const initialTutorialData = {
    id: "",
    title: "",
    subTitle: "",
    content: "",
    link: "",
    isPublish: "",
  };

  const [currentData, setCurrentData] = useState(initialTutorialData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentData({ ...currentData, [name]: value });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentData.title) newErrors.title = "Title is required";
    if (!currentData.subTitle) newErrors.subTitle = "Sub Title is required";
    if (!currentData.content) newErrors.content = "Content is required";
    if (!currentData.link) newErrors.imageUrl = "Video Link is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const EditTutorials = async () => {
    TutorialsService.getTutotial(id)
      .then((response) => {
        const data = response.data.data;
        setCurrentData(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    EditTutorials();
  }, []);

  const updateTutorial = () => {
    if (!validateFields()) return;
    debugger;
    TutorialsService.updateTutotial(currentData.id, currentData)
      .then((response) => {
        setCurrentData({ ...currentData });
        console.log(response.data);
        setOpen(true);
        setSeverity("success");
        setMessage(response.data.message);
        EditTutorials();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Edit Tutorial")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/dashboard/admin/settings/tutorials"
                  >
                    {t("Back")}
                  </Button>
                </div>
              </div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ marginTop: 2 }}
                >
                  <div>
                    <TextField
                      fullWidth
                      id="id"
                      label="Id"
                      name="id"
                      value={currentData.id}
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="title"
                      label={t("Title")}
                      name="title"
                      value={currentData.title}
                      onChange={handleInputChange}
                      error={!!errors.title}
                      helperText={errors.title}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="subTitle"
                      label={t("Sub Title")}
                      name="subTitle"
                      value={currentData.subTitle}
                      onChange={handleInputChange}
                      error={!!errors.subTitle}
                      helperText={errors.subTitle}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="link"
                      label={t("Video Link")}
                      name="link"
                      value={currentData.link}
                      onChange={handleInputChange}
                      error={!!errors.link}
                      helperText={errors.link}
                    />
                  </Grid>
                </Grid>
              </Box>
              <div>
                <p>{t("Content")} :</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={currentData.content}
                  config={{
                    extraPlugins: [uploadPlugin], // Add custom upload logic if required
                    mediaEmbed: {
                      previewsInData: true,
                    },
                    toolbar: [
                      "heading",
                      "mediaEmbed",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "imageUpload",
                      "blockQuote",
                      "bulletedList",
                      "numberedList",
                      "undo",
                      "redo",
                    ],
                    image: {
                      toolbar: [
                        "imageTextAlternative",
                        "imageStyle:full",
                        "imageStyle:side",
                      ],
                    },
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setCurrentData((prevData) => ({
                      ...prevData,
                      content: data,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      content: null,
                    }));
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
                {errors.content && (
                  <p style={{ color: "red" }}>{errors.content}</p>
                )}
              </div>
              <Button
                disabled={changesMade}
                variant="contained"
                onClick={updateTutorial}
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : t("Update")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default EditTutorials;
