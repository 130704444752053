import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getBlogs = (page, pageSize, search, sortBy, sortDir) => {
  return http.get(
    `/blog/admin/pagination?title=${search}&pageNumber=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const getBlog = (id) => {
  return http.get(`/blog/get/${id}`);
};
const addBlog = (data) => {
  debugger;
  return http.post(`/blog/save`, data, {
    headers: { ...authHeader() },
  });
};

const updateBlog = (id, data) => {
  debugger;
  return http.put(`/blog/edit/${id}`, data);
};

const imgeUpload = (data) => {
  debugger;
  return http.post(`/upload/blog/image`, data);
};

const isPublish = (id, status) => {
  return http.patch(`/blog/${id}/publish?isPublish=${status}`);
};

const deleteBlog = (id) => {
  return http.delete(`/blog/delete/${id}`);
};

const imageUploadCKEditor = (formData) => {
  return http.post(`/upload/blog/ckeditor/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const BlogService = {
  getBlogs,
  getBlog,
  updateBlog,
  addBlog,
  imgeUpload,
  isPublish,
  deleteBlog,
  imageUploadCKEditor,
};

export default BlogService;
