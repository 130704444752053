import http from "../../../../baseURL/httpcommon";

const getBillings = (page, pageSize, transactionId, sortBy, sortDir) => {
  return http.get(
    `payment/all?pageNumber=${page}&pageSize=${pageSize}&transactionId=${transactionId}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};
const createCompany = (data) => {
  return http.post("/auth/company/sub/signup", data);
};

const createEnterpriseCompany = (data) => {
  return http.post("/auth/company/sub/signup/enterprise", data);
};
const viewRequests = () => {
  return http.get("company/new/request");
};
const changeStatus = (email, event) => {
  const data = {
    email: email,
    status: event,
  };
  return http.patch(`admin/company/change/status`, data);
};
const changeCompanyStatus = (email, event) => {
  const data = {
    email: email,
    status: event,
  };
  return http.patch(`company/change/status`, data);
};

const PaymentsApi = {
  getBillings,
  viewRequests,
  changeStatus,
  changeCompanyStatus,
  createCompany,
  createEnterpriseCompany,
};

export default PaymentsApi;
