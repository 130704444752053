import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import ViewDetails from "./viewDetails";
import { t } from "i18next";
import BlogService from "./services";
import { formatDateOnly } from "../../../../helper/DateFormater";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "react-bootstrap";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Custom plugin to use upload adapter

const Blog = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [sortBy, setSortBy] = useState("createdOn"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir
  const [search, setSearch] = useState("");

  const onSearchChange = (e) => {
    const search = e.target.value;
    setSearch(search);
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    setLoading(true);
    BlogService.getBlogs(page, pageSize, search, sortBy, sortDir).then(
      (respons) => {
        const { totalPages, totalElements, currentPage, content } =
          respons.data.data;
        setCount(totalPages);
        setTotalItems(totalElements);
        setData(content);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    changeList();
  }, [page, pageSize, sortBy, sortDir]);

  const columns = [
    { field: "id", headerName: t("Blog Id"), width: 100 },
    { field: "title", headerName: t("Title"), flex: 1 },

    {
      field: "createdOn",
      headerName: t("Created At"),
      flex: 1,
      renderCell: (param) => {
        return formatDateOnly(param.row.createdOn);
      },
    },
    {
      field: "publish",
      headerName: t("Is Active"),
      width: 120,
      sortable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => {
        const toggleStatus = async () => {
          setLoading(true);
          const newStatus = !params.value; // Toggle the status
          try {
            // Update status via API call
            const response = await BlogService.isPublish(
              params.row.id,
              newStatus
            );

            const updatedData = data?.map((row) => {
              // Log the row id to the console
              console.log("Mapping Row ID:", row.id);

              return row.id === params.row.id
                ? { ...row, publish: newStatus }
                : row;
            });

            setData(updatedData);
            setOpen(true);
            setSeverity("success");
            setMessage(response.data.message);
            // Optionally update params.value directly (may not be necessary)
            params.value = newStatus;
            getCompanies();
          } catch (error) {
            console.error("Error updating status:", error);
            setOpen(true);
            setSeverity("error");
            setMessage("Something when wrong!");
          }
          setLoading(false);
        };

        return (
          <FormControlLabel
            control={
              <IOSSwitch
                checked={params.value}
                onChange={toggleStatus}
                sx={{ m: 1 }}
              />
            }
          />
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        const onClickEdit = async () => {
          // BlogService.getBlog(params.row.id)
          //   .then((response) => {
          //     const blogData = response.data.data;
          //     setBlogImage(blogData.imageUrl);
          //     // Transform the data to set imageUrl to the value of imageName
          //     const updatedData = {
          //       ...blogData,
          //       imageUrl: blogData.imageName, // Set imageUrl to imageName
          //     };
          //     setCurrentData(updatedData);
          //   })
          //   .catch((error) => {
          //     console.log("error", error);
          //   });
          navigate(`/dashboard/admin/settings/edit-blog/${params.row.id}`);
        };

        const onClickDelete = () => {
          setSelectedTutorial(params.row);
          handleShowConfirmationModal();
        };

        return (
          <>
            <Button
              onClick={() => {
                onClickEdit();
                // handleShowEditProduct();
              }}
              variant="contained"
              size="small"
              color="primary"
              style={{ marginLeft: 16 }}
              startIcon={<EditIcon />}
            >
              {t("Edit")}
            </Button>
            <Button
              onClick={() => {
                onClickDelete();
              }}
              variant="contained"
              size="small"
              color="error"
              style={{ marginLeft: 16 }}
              startIcon={<DeleteIcon />}
            >
              {t("Delete")}
            </Button>
          </>
        );
      },
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const [showEditProduct, setShowEditProduct] = useState(false);
  // const handleCloseEditProduct = () => setShowEditProduct(false);
  // const handleShowEditProduct = () => setShowEditProduct(true);

  // const updateBlog = () => {
  //   if (!validateFields()) return;
  //   debugger;
  //   BlogService.updateBlog(currentData.id, currentData)
  //     .then((response) => {
  //       setCurrentData({ ...currentData });
  //       setFile(null);
  //       console.log(response.data);
  //       setOpen(true);
  //       setSeverity("success");
  //       setMessage(response.data.message);
  //       handleCloseEditProduct();
  //       getCompanies();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // const [file, setFile] = useState(null);

  // const handleChange = async (newFiles) => {
  //   debugger;
  //   setChangesMade(true);
  //   if (newFiles.length > 0) {
  //     setFile(newFiles[0]);
  //     const uploadFile = new FormData();
  //     uploadFile.append("multipartFile", newFiles[0]);
  //     try {
  //       const response = await BlogService.imgeUpload(uploadFile);
  //       setCurrentData((prevData) => {
  //         return { ...prevData, imageUrl: response.data.message };
  //       });
  //       setChangesMade(false);
  //       setErrors((prevErrors) => ({ ...prevErrors, imageUrl: null }));
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //       setChangesMade(false);
  //       // Handle error appropriately
  //     }
  //   }
  // };

  // const handleFileRemove = () => {
  //   setFile(null);
  //   setCurrentData((prevData) => ({ ...prevData, imageUrl: "" }));
  // };

  // const handleError = (error, file) => {
  //   console.log("error code " + error.code + ": " + error.message);
  // };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);

  const deleteBlog = async () => {
    try {
      await BlogService.deleteBlog(selectedTutorial.id);
      changeList();
      setOpen(true);
      setSeverity("success");
      setMessage("Blog deleted successfully");
      handleCloseConfirmationModal();
    } catch (error) {
      console.log("Error deleting blog:", error);
      setOpen(true);
      setSeverity("error");
      setMessage("Something went wrong!");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = () => {
    setPage(0);
    changeList();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Blog List")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/dashboard/admin/settings/blog/add"
                  >
                    {t("Add New Blog")}
                  </Button>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    id="search"
                    size="small"
                    name="search"
                    label={`${t("Search by Title")}`}
                    variant="outlined"
                    value={search}
                    onChange={onSearchChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    {t("SEARCH")}
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={pageSize}
                page={page}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="id"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        show={showEditProduct}
        onHide={handleCloseEditProduct}
        size="lg"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderBottom:
              "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ marginTop: 2 }}
            >
              <div>
                <TextField
                  fullWidth
                  id="id"
                  label="Id"
                  name="id"
                  value={currentData.id}
                  onChange={handleInputChange}
                  hidden
                />
              </div>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  value={currentData.title}
                  onChange={handleInputChange}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="subTitle"
                  label="Sub Title"
                  name="subTitle"
                  value={currentData.subTitle}
                  onChange={handleInputChange}
                  error={!!errors.subTitle}
                  helperText={errors.subTitle}
                />
              </Grid>
            </Grid>
          </Box>
          <div>
            <p>Content :</p>
            <CKEditor
              editor={ClassicEditor}
              data={currentData.content}
              config={{
                ckfinder: {
                  // Upload the images to the server using the CKFinder QuickUpload command
                  // You have to change this address to your server that has the ckfinder php connector
                  uploadUrl: "", //Enter your upload url
                },
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCurrentData((prevData) => ({
                  ...prevData,
                  content: data,
                }));
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  content: null,
                }));
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            {errors.content && <p style={{ color: "red" }}>{errors.content}</p>}
            <div style={{ marginTop: 10 }}>
              <p>Blog Image: </p>
              <img height={100} width={100} src={blogImage} />
            </div>
            <div className="files" style={{ marginTop: 20 }}>
              <Files
                className="files-dropzone"
                onChange={handleChange}
                onError={handleError}
                accepts={["image/png", "image/jpg", "image/jpeg"]}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                Drop files here or click to upload
              </Files>
              {errors.imageUrl && (
                <p style={{ color: "red" }}>{errors.imageUrl}</p>
              )}
              {file && (
                <div className="files-list">
                  <ul>
                    <li key={file.id} className="files-list-item">
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <img
                            className="files-list-item-preview-image"
                            src={file.preview.url}
                          />
                        ) : (
                          <div className="files-list-item-preview-extension">
                            {file.extension}
                          </div>
                        )}
                      </div>
                      <div className="files-list-item-content">
                        <div className="files-list-item-content-item files-list-item-content-item-1">
                          {file.name}
                        </div>
                        <div className="files-list-item-content-item files-list-item-content-item-2">
                          {file.sizeReadable}
                        </div>
                      </div>
                      <div
                        className="files-list-item-remove"
                        onClick={handleFileRemove}
                      />
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleCloseEditProduct}
          >
            Close
          </Button>
          <Button
            disabled={changesMade}
            variant="contained"
            onClick={updateBlog}
            sx={{ ml: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={showConfirmationModal}
        onHide={handleCloseConfirmationModal}
        style={{ marginTop: "100px" }}
      >
        <Modal.Body className="modal-content text-center">
          <div className="modal-body font-18 p-5 text-dark">
            {t("Are you sure you want to delete this Blog?")}
          </div>

          <div className="pb-5 border-0">
            <button
              type="button"
              className="btn btn-primary px-4 py-3 w-25"
              style={{ marginRight: "1.5rem" }}
              onClick={deleteBlog}
            >
              {t("Delete")}
            </button>

            <button
              type="button"
              className="btn btn-danger px-4 py-3 w-25 "
              data-bs-dismiss="modal"
              onClick={handleCloseConfirmationModal}
            >
              {t("Cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Blog;
