import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CompaniesApi from "./services";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Portal, TextField, Button } from "@mui/material";

const ViewDetails = ({ data, refreshData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  console.log("ViewDetails", data);

  // State for main modal and reason modal
  const [show, setShow] = useState(false);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnRejectLoading, setRejectBtnLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(""); // Track which action is being taken
  const [modelData, setModelData] = useState({
    name: "",
    number: "",
    regNo: "",
    email: "",
    taxAgencyno: "",
    registrationNo: "",
    ecompanyRequestType: "",
    planName: "",
    shareCount: "",
  });
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [rejectionReason, setRejectionReason] = useState("");

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleReasonModalClose = () => setReasonModalOpen(false);
  const handleReasonModalOpen = () => setReasonModalOpen(true);

  function setViewDetail(data) {
    setShow(true);
    setModelData({
      ...modelData,
      name: data.companyName,
      number: data.phoneNo,
      regNo: data.registrationNo,
      email: data.email,
      taxAgencyno: data.taxAgencyNo,
      ssn: data.ssn,
      registrationNo: data.registrationNo,
      ecompanyRequestType: data.ecompanyRequestType,
      planName: data?.userSubscriptionPlan?.subscriptionPlanId?.planName,
      shareCount: data?.userSubscriptionPlan?.subscriptionPlanId?.shareCount,
    });
  }

  function approve() {
    setBtnLoading(true);
    setAction("approve");
    CompaniesApi.changeStatus(modelData.email, true)
      .then((response) => {
        setShow(false);
        setMessage(response.data.message);
        setSeverity("success");
        setOpen(true);
        setBtnLoading(false);
        refreshData();
      })
      .catch((error) => {
        setBtnLoading(false);
        setAction("");
      });
  }

  function reject() {
    setRejectBtnLoading(true);
    CompaniesApi.changeStatus(modelData.email, false, rejectionReason)
      .then((response) => {
        setShow(false);
        setMessage(response.data.message);
        setSeverity("success");
        setOpen(true);
        setRejectBtnLoading(false);
        setRejectionReason(""); // Clear reason after use
        refreshData();
      })
      .catch((error) => {
        setRejectBtnLoading(false);
        setAction("");
      });
  }

  const handleRejectWithReason = () => {
    handleReasonModalOpen();
  };

  const handleConfirmReject = () => {
    reject();
    handleReasonModalClose();
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary px-2 font-14"
        onClick={() => setViewDetail(data)}
      >
        {t("View Details")}
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Company Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 mb-3">
              {/* <div className="px-4 pt-4 text-center">
                <img src="/assets/img/avatardefault_92824.png" style={{ width: "100px", height: "auro" }} />
              </div> */}
              <div className="px-lg-4 pt-5 px-3">
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <label> {t("Company_name")}</label>
                    <div className="border-bottom2 height-50">
                      {/* <span className="font-14 text-dark w_50"> </span> */}
                      <span className="font-14 text-gray  w_50">
                        {" "}
                        {modelData.name}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mb-3">
                    <label>{t("Phone No")}</label>
                    <div className="border-bottom2 height-50">
                      {/* <span className="font-14 text-dark w_50"> </span> */}
                      <span className="font-14 text-gray  w_50">
                        {" "}
                        {modelData.number}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <label>{t("Email")}</label>
                    <div className="border-bottom2 height-50">
                      {/* <span className="font-14 text-dark w_50"> Email</span> */}
                      <span className="font-14 text-gray  w_50 text-wrap">
                        {" "}
                        {modelData.email}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mb-3">
                    <label>{t("Personal/Social_security_number")}</label>
                    <div className="border-bottom2 height-50">
                      {/* <span className="font-14 text-dark w_50"> </span> */}
                      <span className="font-14 text-gray  w_50">
                        {" "}
                        {modelData.ssn}
                      </span>
                    </div>
                  </div>
                </div>

                {/* {location.pathname === "/dashboard/admin/companies" ? ( */}
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <label>{t("Corporate_Reg no.")}</label>
                    <div className="border-bottom2 height-50">
                      {/* <span className="font-14 text-dark w_50"> </span> */}
                      <span className="font-14 text-gray  w_50">
                        {" "}
                        {modelData.registrationNo}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mb-3">
                    <label>{t("Tax_Agency_no.")}</label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-gray  w_50">
                        {" "}
                        {modelData.taxAgencyno}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-12 mb-3">
                    <label>{t("Requested Package")}</label>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span className="font-14 text-dark">
                        {modelData?.planName}
                      </span>
                      <span className="font-14 text-gray">
                        {modelData?.shareCount} Licenses
                      </span>
                    </div>
                  </div>
                </div>

                {/* ) : null} */}

                {location.pathname ===
                  "/dashboard/admin/companies/viewrequests" &&
                modelData.ecompanyRequestType === "Pending" ? (
                  <div className="row text-center mt-4">
                    <div className="col-md-12 col-12 mb-3">
                      <button
                        className="btn btn-success2 px-3 px-3"
                        style={{ marginRight: "5px" }}
                        onClick={approve}
                        disabled={btnLoading || action === "reject"} // Disable if loading or rejected
                      >
                        {btnLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Approve"
                        )}
                      </button>
                      <button
                        className="btn btn-danger2 px-3"
                        style={{ marginRight: "5px" }}
                        onClick={handleRejectWithReason}
                        disabled={btnRejectLoading || action === "approve"} // Disable if loading or approved
                      >
                        {btnRejectLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Reject"
                        )}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Reason Modal */}
      <Modal
        show={reasonModalOpen}
        onHide={handleReasonModalClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Reason for Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Reason for Rejection"
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleReasonModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmReject}
            disabled={!rejectionReason.trim()} // Disable if reason is empty
            sx={{ ml: 2 }}
          >
            Confirm Reject
          </Button>
        </Modal.Footer>
      </Modal>

      <Portal>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};
export default ViewDetails;
