import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import NotificaionServices from "./services";
import { grey } from "@mui/material/colors";
import { truncateName40 } from "../../../../helper/StringSlicer";

const AdminNotification = () => {
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [notification, setNotification] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  const workforceId = jwt?.workforceId;

  const getNotification = () => {
    setLoading(true); // Set loading to true when starting the fetch
    NotificaionServices.getNotification(workforceId)
      .then((response) => {
        const data = response.data.data.notificationList;
        setNotification(data);
        setLoading(false); // Set loading to false once the fetch is complete
      })
      .catch((error) => {
        setLoading(false); // Set loading to false if there is an error
        if (error.response) {
          if (error.response.status === 404) {
            console.log("Notification not found:", error.data);
          } else if (
            error.response.status === 500 ||
            error.response.status === 400 ||
            error.response.status === 504
          ) {
            console.log("Error:", error.message);
          } else {
            console.log("Other error:", error.response.data);
          }
        } else {
          console.log("Error setting up request:", error.message);
        }
      });
  };

  useEffect(() => {
    getNotification();
  }, []);

  const handleNotification = (id) => {
    NotificaionServices.findNotificationById(id)
      .then((response) => {
        // Update the status of the notification
        const updatedNotifications = notification.map((notif) =>
          notif.id === id ? { ...notif, status: "DELIVERED" } : notif
        );
        setNotification(updatedNotifications);
        setSelectedNotification(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  function formatDateWithDay(inputDate) {
    const currentDate = new Date();
    const messageDate = new Date(inputDate);
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const messageDay = messageDate.getDate();
    const messageMonth = messageDate.getMonth();
    const messageYear = messageDate.getFullYear();

    if (
      currentDay === messageDay &&
      currentMonth === messageMonth &&
      currentYear === messageYear
    ) {
      let hours = messageDate.getHours();
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutes = messageDate.getMinutes();
      return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    } else if (
      currentDay - messageDay === 1 &&
      currentMonth === messageMonth &&
      currentYear === messageYear
    ) {
      return "Yesterday";
    } else {
      const formattedDate = `${messageDay}/${(messageMonth + 1)
        .toString()
        .padStart(2, "0")}/${messageYear.toString().slice(2)}`;
      return formattedDate;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      display="flex"
      p={2}
      borderRadius={2}
      boxShadow={1}
      maxWidth={900}
      bgcolor="white"
    >
      <Box flex={1} p={2} bgcolor="white">
        <Typography variant="h6" gutterBottom>
          Notifications
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : notification.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={4}
            flexDirection="column"
          >
            <img
              src="/assets/images/icons/speech-bubble.png"
              alt="Logo"
              style={{ marginBottom: 16 }}
            />
            <Typography variant="h6" color="textSecondary">
              No Notification Found
            </Typography>
          </Box>
        ) : (
          <Box maxHeight={550} overflow="auto">
            {notification.map((notification) => (
              <Box
                key={notification.id}
                display="flex"
                alignItems="flex-start"
                p={2}
                borderBottom={`1px solid ${grey[300]}`}
                onClick={() => {
                  handleNotification(notification.id);
                  setSelectedNotification(notification);
                }}
                sx={{ cursor: "pointer" }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    {notification.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {truncateName40(notification.description)}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formatDateWithDay(notification.timestamp)}
                  </Typography>
                  {notification.status === "RECEIVED" && (
                    <Chip
                      label="Unread"
                      color="primary"
                      size="small"
                      style={{ float: "right" }}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {selectedNotification && (
        <Box flex={1} p={2} borderLeft={`1px solid ${grey[300]}`}>
          <Typography variant="h6" gutterBottom>
            Notification Details
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {selectedNotification.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {selectedNotification.description}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {formatDateWithDay(selectedNotification.timestamp)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AdminNotification;
