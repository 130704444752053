import { useEffect, useState } from "react";
import SettingApi from "./services";
import { Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { isValidName } from "../../../../helper/regex";

const ApplicationSetting = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [changesMade, setChangesMade] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const dataJson = {
    id: 1,
    fullName: "",
    shortName: "",
    applicationUrl: "",
    apiUrl: "",
    facebookUrl: "",
    youtubeUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    adminUrl: "",
    supervisorUrl: "",
    companyUrl: "",
    userImagesUrl: "",
    workerReportImagesUrl: "",
    workerDocumentImagesUrl: "",
  };
  const [data, setData] = useState(dataJson);

  // Utility function to validate URL
  const isValidUrl = (url) => {
    const urlRegex =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlRegex.test(url);
  };

  function setValue(e) {
    const { name, value } = e.target;

    if (name === "fullName" && !isValidName(value)) {
      return;
    }
    if (name === "shortName" && !isValidName(value)) {
      return; // If the input does not match the regex, return early
    }

    setData({
      ...data,
      [name]: value,
    });

    setChangesMade(false);
    console.log(data);
  }

  function applicationGet() {
    SettingApi.applicationGet().then((respons) => {
      const responsData = respons.data.data;
      console.log(responsData);
      setData({
        ...data,
        fullName: responsData.fullName,
        shortName: responsData.shortName,
        applicationUrl: responsData.applicationUrl,
        apiUrl: responsData.apiUrl,
        facebookUrl: responsData.facebookUrl,
        youtubeUrl: responsData.youtubeUrl,
        twitterUrl: responsData.twitterUrl,
        instagramUrl: responsData.instagramUrl,
        adminUrl: responsData.adminUrl,
        supervisorUrl: responsData.supervisorUrl,
        companyUrl: responsData.companyUrl,
        userImagesUrl: responsData.userImagesUrl,
        workerReportImagesUrl: responsData.workerReportImagesUrl,
        workerDocumentImagesUrl: responsData.workerDocumentImagesUrl,
      });
    });
  }
  function applicationUpdate() {
    if (data?.fullName?.trim() === "" || data?.shortName?.trim() === "") {
      // If any required field is empty, show error message
      setMessage("Fill all required fields.");
      setSeverity("error");
      setOpen(true);
      return;
    }

    const urlFields = [
      "applicationUrl",
      "apiUrl",
      "facebookUrl",
      "youtubeUrl",
      "twitterUrl",
      "instagramUrl",
      "adminUrl",
      "supervisorUrl",
      "companyUrl",
    ];

    for (let field of urlFields) {
      if (!isValidUrl(data[field])) {
        setMessage(`Invalid URL format for ${field.replace(/Url$/, " URL")}.`);
        setSeverity("error");
        setOpen(true);
        return;
      }
    }

    SettingApi.applicationUpdate(data)
      .then((respons) => {
        console.log(respons);
        if (respons.data.status == true) {
          setOpen(true);
          setMessage(respons.data.message);
          setSeverity("success");
          setChangesMade(true);
        }
      })
      .catch((e) => {
        if (e.response.status == 404 || e.response.status == 400) {
          setOpen(true);
          setMessage(e.response.data.message);
          setSeverity("error");
          setChangesMade(true);
        }
      });
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      applicationUpdate();
    }

    setValidated(true);
  };
  useEffect(() => {
    applicationGet();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h5 className="mb-0">{t("Application Information")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Full Name")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    max={25}
                    placeholder="Full Name"
                    value={data.fullName}
                    name="fullName"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Short Name")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    max={25}
                    placeholder="Short Name"
                    value={data.shortName}
                    name="shortName"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Application URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Application URL"
                    value={data.applicationUrl}
                    name="applicationUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("API URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="API URL"
                    value={data.apiUrl}
                    name="apiUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Facebook URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Facebook URL"
                    value={data.facebookUrl}
                    name="facebookUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Youtube URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Youtube URL"
                    value={data.youtubeUrl}
                    name="youtubeUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Instagram URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Instagram URL"
                    value={data.instagramUrl}
                    name="instagramUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Twitter URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Instagram URL"
                    value={data.twitterUrl}
                    name="twitterUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Admin URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Admin URL"
                    value={data.adminUrl}
                    name="adminUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Supervisor URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Book image  URL"
                    value={data.supervisorUrl}
                    name="supervisorUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Company URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    placeholder="Company  URL"
                    value={data.companyUrl}
                    name="companyUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("User Images URL")}</Form.Label>
                  <Form.Control
                    //required
                    disabled
                    type="text"
                    placeholder="User Images URL"
                    value={data.userImagesUrl}
                    name="userImagesUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Worker Report Images URL")}</Form.Label>
                  <Form.Control
                    //required
                    disabled
                    type="text"
                    placeholder="Worker Report Images URL"
                    value={data.workerReportImagesUrl}
                    name="workerReportImagesUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Worker Document Images URL")}</Form.Label>
                  <Form.Control
                    //required
                    type="text"
                    disabled
                    placeholder="Worker Document Images URL"
                    value={data.workerDocumentImagesUrl}
                    name="workerDocumentImagesUrl"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <button
                  disabled={changesMade}
                  type="button"
                  class="btn btn-primary mt-4 px-4"
                  onClick={handleSubmit}
                >
                  {t("Save")}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default ApplicationSetting;
